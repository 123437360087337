<template>
  <div class="products">
    <Breadcrumb class="d-none d-sm-flex px-0" :items="breadcrumb" />
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <v-container>
      <CategoryTitle :category="category" :count="count" />

      <v-row>
        <v-col
          cols="12"
          :md="showRightColumn ? 8 : 12"
          :xl="showRightColumn ? 9 : 12"
        >
          <div
            v-if="
              !$ebsn.meta(this.category, 'template_model.HIDE_FILTERS', false)
            "
            class="d-flex"
          >
            <v-select
              v-for="articleClass in articleClasses"
              dense
              :label="articleClass.name"
              :key="articleClass.id"
              :items="articleClass.values"
              item-text="name"
              item-value="id"
              @change="handleFilterChange"
              :to="{
                query: { parentArticleClassId: articleClass.id }
              }"
              class="mr-2 article-classes-select"
              clearable
              outlined
              return-object
            >
            </v-select>
          </div>
          <v-row
            ><v-col
              cols="12"
              v-for="article in articleList"
              :key="article.articleId"
            >
              <ArticleCardHorizontal :article="article" /> </v-col
          ></v-row>
          <v-pagination
            v-if="pager && pager.totPages > 1"
            v-model="pager.selPage"
            :length="pager.totPages"
            :totalVisible="$vuetify.breakpoint.xs ? 4 : 6"
            color="primary"
            circle
            @next="handlePageChange"
            @prev="handlePageChange"
            @input="handlePageChange"
          ></v-pagination>
        </v-col>
        <v-col v-if="showRightColumn" cols="12" md="4" xl="3">
          <category-block
            :target="category"
            position="position3"
            class="category-block category-block-3"
          />
          <category-block
            :target="category"
            position="position4"
            class="category-block category-block-4"
          />
          <category-block
            :target="category"
            position="position5"
            class="category-block category-block-5"
          />
        </v-col>
      </v-row>
    </v-container>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style>
.article-classes-select {
  .v-icon.v-icon {
    font-size: 12px !important;
  }
}
</style>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import ArticleCardHorizontal from "@/components/article/ArticleCardHorizontal.vue";

import CategoryTitle from "@/components/category/CategoryTitle.vue";

import cmService from "~/service/mksCmService";
import categoryMixins from "~/mixins/category";

export default {
  components: {
    Breadcrumb,
    CategoryTitle,
    ArticleCardHorizontal
  },
  name: "ArticleClassGrid",
  mixins: [categoryMixins],
  data() {
    return {
      articleList: [],
      pager: null,
      page: 0,
      count: 0
    };
  },
  computed: {
    showRightColumn() {
      return this.$ebsn.meta(this.category, "template_model.SHOW_RIGHT_COLUMN");
    }
  },
  methods: {
    async reload() {
      let parentArticleTypeId = this.$ebsn.meta(
        this.category,
        "template_model.ARTICLE_TYPE"
      );
      let parentArticleClassId = this.$ebsn.meta(
        this.category,
        "template_model.ARTICLE_CLASS_FILTER"
      );
      let getArticleContent = this.$ebsn.meta(
        this.category,
        "template_model.SHOW_CONTENT"
      );
      let sort = this.$ebsn.meta(this.category, "template_model.ARTICLE_SORT");
      let params = {
        parent_article_type_id: parentArticleTypeId,
        parent_article_class_id: parentArticleClassId,
        show_content: getArticleContent,
        sort: sort,
        page_size: 24
      };
      for (let key in this.$route.query) {
        if (key.indexOf("article_class") > -1) {
          if (!params["article_class_id"]) params["article_class_id"] = [];
          params["article_class_id"].push(this.$route.query[key]);
        } else {
          params[key] = this.$route.query[key];
        }
      }

      let content = await cmService.searchArticle(params);
      this.articleList = content?.data?.articles;
      this.pager = content?.data?.page;
      this.articleClasses = content?.data?.facets;
      this.key++;
    },
    handlePageChange(page) {
      // let queryParam = Object.assign(this.$route.query, { page: page });
      // this.$router.push({
      //   path: this.$route.path,
      //   query: queryParam
      // });
      if (page) {
        const query = { ...this.$route.query, page: page };
        this.$router.push({ query });
      }
    },
    handleFilterChange() {
      let to = {
        // name: this.$route.name,
        query: { page: 1 }
      };
      for (let key in this.selectedArticleClasses) {
        if (this.selectedArticleClasses[key]) {
          to.query[key] = this.selectedArticleClasses[key].id;
        }
      }

      this.$router.push(to);
    }
  },
  created() {
    this.reload();
  },
  watch: {
    "$route.query.page": function() {
      this.reload();
    },
    "$route.query.article_class_id": function() {
      this.reload();
    }
  }
};
</script>
