<template>
  <v-card
    outlined
    class="article-card-horizontal align-center grey"
    :to="{ name: article.articleType.name, params: { pageName: article.slug } }"
  >
    <v-row no-gutter class="w-100">
      <v-col cols="5" class="px-0 ">
        <ebsn-media
          :target="article"
          type="Article main"
          media="thumb"
          width="100%"
          min-width="100%"
          min-height="100%"
          :cover="true"
        />
      </v-col>
      <v-col cols="7">
        <div class="py-4">
          <template v-if="size != 'small'">
            <v-chip v-if="course" color="primary" class="article-course">
              {{ course }}
            </v-chip>

            <v-chip
              v-if="article.articleType.name == 'recipe'"
              color="primary"
              class="article-course"
            >
              {{ course }}
            </v-chip>
            <div v-if="article.articleType.name == 'post'" class="d-flex">
              <v-chip
                v-for="category in categories"
                :key="category.id"
                color="primary"
                class="article-course mr-2"
              >
                {{ category.name }}
              </v-chip>
            </div>
          </template>
          <v-card-subtitle v-if="article.pubDate == 'post'" class="small--text">
            {{ $dayjs(article.pubDate, "DD/MM/YYYY").format("DD MMMM YYYY") }}
          </v-card-subtitle>

          <v-card-title>{{ article.title }}</v-card-title>
          <v-card-text>
            {{ $ebsn.meta(article, "articletype.recipe_description") }}
            <div
              class="is-content"
              v-if="article.content"
              v-html="article.content"
            ></div>
            <template v-else>
              {{ article.contentAbstract }}
            </template>
          </v-card-text>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<style lang="scss">
.article-card-horizontal {
  height: 100%;
  display: flex;
  flex-direction: column;
  .v-card__subtitle {
    font-size: 12px !important;
    padding: 4px 16px;
  }
}
</style>
<script>
export default {
  name: "ArticleCard",
  props: {
    article: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: "normal"
    }
  },
  computed: {
    course() {
      return this.article?.articleClass?.find(
        ac => ac.articleClassGroup.name == "course"
      )?.name;
    },
    categories() {
      return this.article?.articleClass?.filter(
        ac => ac.articleClassGroup.name == "category"
      );
    }
  }
};
</script>
